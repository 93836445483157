.label {
    font-size: 14px;
    color: #717681;
}

.tr_date, .tr_time, .tr_wday, .tr_cron {

    td {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        label {
            margin: 0 10px;
        }
    }

    .input_num {
        flex: 0 0 100px;
    }
}
