.wrapper {
    display: block;
    line-height: 10px;

    .input {
        display: none;
    }
    .label {
        height: 16px;
        font-size: 15px;
        line-height: 16px;
        padding-left: 24px;
        position: relative;
        &:hover {
            cursor: pointer;
        }
        &::before {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: 0;
            border-radius: 4px;
            border: 1px solid var(--neutral50);
        }
    }
    &.readonly label {
        cursor: default;
    }
    &.wrapper_active {
        .label {
            &::before {
                background-color: var(--primary60);
                border-color: var(--primary60);
            }
            &::after {
                content: '';
                position: absolute;
                width: 11px;
                height: 8px;
                left: 3px;
                top: 4px;
                background-image: url(../../assets/icons/checked.svg);
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}