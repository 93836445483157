.form {
    background-color: var(--white);
    padding: 32px 46px 40px;
    border-radius: 6px;
}
.title {
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    margin-bottom: 37px;
}

.btn {
    margin-top: 25px;
    &_wrapper {
        display: flex;
        justify-content: center;
    }
}

.input_wrapper {
    display: flex;
    flex-direction: column;
}