@import '../../assets/css/common.scss';

.error {
    color: red;
}


.table {
    width: 100%;
    min-width: 700px;
    margin-bottom: 70px;

    th {
        width: 200px;
    }

    &_wrapper {
        overflow-x: auto;
    }

    &_scroll {
        width: max-content;
        overflow-x: auto;
    }
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit2.svg);
    width: 20px;
    height: 20px;
    background-size: contain;
    margin: 0 5px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg);
    width: 20px;
    height: 20px;
    background-size: contain;
    margin: 0 5px;
}

.btn_fav {
    display: inline-block;
    background: url(../../assets/icons/star-grey.svg);
    width: 20px;
    height: 20px;
    background-size: contain;
    margin: 0 5px;

    &.active {
        background-image: url(../../assets/icons/star-y.svg);
    }
}

.actions {
    display: flex;

    .btn_add {
        cursor: pointer;
        margin-left: 15px;
        border: 1px solid #C85003;
        border-radius: 4px;
        width: 44px;
        height: 44px;
        flex: 0 0 44px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            fill: #C85003;
        }
    }
}

.tree {
    table {
        max-width: 100%;
        width: 100%;
    }
    th span {
        display: inline-flex;
        
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
    }
    thead tr:first-child th {
        border-bottom: 1px solid #D5D8DE;
        position: relative;
        padding-left: 5px;
        padding-right: 5px;
        
        &:not(:first-child)::before {
            content: ' ';
            border-left: 1px solid #D5D8DE;
            position: absolute;
            left: 0;
            top: 8px;
            bottom: 8px;
        }

        svg {
            margin-left: 5px;
        }
        
    }
    td {
        padding: 8px 0;
        border-bottom: 1px solid #D5D8DE;
        position: relative;

        &:first-child {
            button {
                float: left;
            }

            > div {
                display: block;
                padding-left: 20px;
            }
        }
    }

    tbody tr {
        cursor: pointer;
        &:hover {
            background: #f2f2f2;
        }
    }

    tr:hover .actions_popup {
        display: block;
    }
}

.wf_bnts {
    display: flex;
    align-items: stretch;
    
    margin-bottom: 20px;
    margin-top: 20px;

    button {
        background: none;
        color: #3B4047;
        font-size: 14px;
        padding: 0;
        text-align: left;
        border-bottom: 1px solid #E5E7EB;
        border-radius: 0;
        display: block;

        &.active,
        &:hover {
            background: none;
            font-weight: 700;
            color: var(--primary60);
        }

        &.active {
            border-bottom: 2px solid var(--primary60);
        }
    }

    .sep {
        border-bottom: 1px solid #E5E7EB;
        border-radius: 0;
        width: 20px;
    }
}

.col_body {
    display: inline-block;

    .actions_popup {
        display: none;
        background: none;
        padding: 3px;
        position: absolute;
        align-items: center;
        height: 30px;
        top: 50%;
        margin-top: -15px;
        right: 10px;
        white-space: nowrap;
        line-height: 30px;
    }
}

.tree_global_search {
    display: flex;
    align-items: center;

    .inp_search {
        width: 322px;
    }
}

.synonyms_row {
    label {
        font-size: 14px;
        font-weight: 400;
        color: #717681;
    }
}

.synonyms_head {
    display: flex;
    align-items: center;
    gap: 12px;
}

.synonym_item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;

    .synonym_select {
        flex: 1;
    }
}