.dashboard {
    
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 8px;
    min-height: 0;
    width: 100%;

    .row1 {
        display: flex;
        align-items: stretch;
        gap: 8px;
        min-height: 0;
        flex: 1 1;
    }

    .row2 {
        position: relative;
    }

    .col1 {
        flex: 629;
        width: 500px;
        
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;

        .recommended {
            flex: 0 0 170px;
            position: relative;
        }

        .popular {
            flex: 1;
        }
    }

    .col2 {
        flex: 312;
        min-height: 0;
        min-width: 0;
    }

    .block {
        background: #fff;
        border-radius: 16px;
        padding: 20px;
        min-height: 0;
    }

    h2 {
        padding: 0;
        font-weight: 700;
        font-size: 20px;
    }

    .popular {
        display: flex;
        flex-direction: column;
    }

    .popular .carousel {
        flex: 1 1;
        align-items: stretch;
    }

    .recommended .carousel {
        margin-top: 12px;
        margin-left: -4px;
        margin-right: -4px;
    }

    .recommended .item {
        flex: 0 0 17%;
        width: 17%;
        border: 1px solid #D5D8DE;
        padding: 12px;
        border-radius: 8px;
        height: 88px;

        .head {
            margin-top: 12px;
            line-height: 16px;
        }
    }

    .popular {
        h2 {
            margin-bottom: 12px;
        }
    }
}

.rec_item {
    display: flex;
    flex-direction: column;
    margin: 0 4px;
    border: 1px solid #D5D8DE;
    padding: 7px;
    border-radius: 8px;
    height: 88px;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        overflow: hidden;
    }

    .head {
        margin-top: 12px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.popular_item {
    height: 100%;
    background: #E6FAF9;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    h3 {
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
    }

    .lnk {
        display: block;
    }

    .desc {
        max-height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
    }

    .info {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.dot {
    background: none;
    border: none;
    margin-bottom: 10px;
}

.slider_right {
    position: absolute;
    display: block;
    border: none;
    background: none;
    opacity: 1;
    right: -10px;
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    
    &::before {
        content: "";
    }

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
}

.custom_arrows {
    display: none;

    button {
        position: absolute;
        display: block;
        background: none;
        border: none;
        padding: 0;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        top: 84px;

        &:hover {
            background: rgba(0,0,0,0.05);
        }
    }

    .left {
        left: 7px;

        svg {
            transform: rotate(180deg);
        }
    }

    .right {
        right: 10px;
    }
}

.recommended:hover .custom_arrows {
    display: block;
}