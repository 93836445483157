@import '../../assets/css/common.scss';





.error {
    color: red;
}

.error {
    background: var(--red10);
}

.button {
    margin-bottom: 20px;
    gap: 10px;
    padding: 10px 20px;
}

.table {
    width: 100%;
    min-width: 700px;
    margin-bottom: 70px;

    th {
        width: 200px;
    }

    &_wrapper {
        overflow-x: auto;
    }

    &_scroll {
        width: max-content;
        overflow-x: auto;
    }
}

.btn_create {
    display: inline-block;
    background: url(../../assets/icons/plus-in-circle-dk.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg);
    width: 12px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.systems_wrap {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    max-width: 750px;

    padding: 8px;
    padding-top: 15px;
    padding-right: 0;
    margin-bottom: 10px;

    .long_input {
        margin-bottom: 10px;
    }
}

.systems_head {



    label {
        font-weight: bold;
    }

    svg {
        cursor: pointer;
        margin-left: 15px;
        fill: var(--orange-primary)
    }
}

.system_item {
    display: flex;
    align-items: center;

    >svg {
        cursor: pointer;
        margin-bottom: 10px;
    }

    >div {
        flex-grow: 1;
    }
}

.row_linked_attribs {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;

    .tbl {
        flex-grow: 1;
    }
}

.row_entity_attribs {
    margin-top: 40px;
}

.hidden .btn_remove_attrib {
    display: none;
}

.synonyms_row {
    svg {
        cursor: pointer;
        margin-left: 15px;
        fill: var(--orange-primary)
    }

    label {
        font-weight: 400;
        font-size: 14px;
        color: #717681;
    }

    .synonym_item {
        display: flex;
        align-items: center;
        margin-top: 8px;

        .select {
            flex: 1 1;
        }
    }
}

.attributes {
    .row_h {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .select_entity {
        width: 260px;
        
    }
}

.btns {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dlg_add_attr {
    > div {
        background: #F1F3F6;
        width: 1050px;
        min-height: 720px;
    }

    .inner {
        display: flex;
        align-items: stretch;
        gap: 8px;
        flex: 1 1;

        .left {
            @include white-block;
            flex: 1 1;
        }

        .right {
            @include white-block;
            flex: 1 1;
            display: flex;
            flex-direction: column;
        }
    }
}

.nodata_msg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 1;

    h5 {
        margin: 10px 0;
        font-size: 20px;
        font-weight: 700;
    }
}