.search_page {
    display: flex;
    align-items: stretch;
    gap: 8px;
    padding-top: 16px;
    flex: 1;
    height: 100%;

    .left {
        background: #fff;
        border-radius: 16px;
        padding: 20px;
        width: 312px;
        flex: 0 0 312px;
        overflow: auto;
    }

    .right {
        background: #fff;
        border-radius: 16px;
        padding: 20px;
        flex: 1;

        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow: auto;

        &.loading {
            opacity: 0.5;
        }
    }


    .btn_filter_at {
        margin-right: 10px;
        background: #fff;
        border: 1px solid var(--blue-70);
        color: var(--blue-70);

        &.active {
            background: var(--blue-70);
            color: #fff;
        }
    }

    .total_msg {
        font-size: 14px;
        margin-bottom: 20px;

        .q {
            font-weight: 700;
        }
    }

    .search_header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .at {
            margin-left: 10px;
        }
    }

    .name {
        display: block;
        margin-bottom: 10px;
    }

    .search_result {
        display: block;
        border: none;
        background: none;
        border-radius: 16px;
        padding: 12px;

        &.archive {
            opacity: 0.5;
        }

        .search_result_link {
            display: block;
            margin-top: 6px;
        }

        h3 {
            margin: 0;
            margin-bottom: 5px;
            font-size: 16px;
            font-weight: 700;
        }

        &:hover {
            background: var(--neutral05);
        }

        .description {
            margin-top: 4px;
            margin-bottom: 8px;
        }

        pre {
            font-size: 14px;
            margin: 0;
            font-family: 'Mulish', sans-serif;
            overflow: hidden;
            white-space: normal;
        }
    }
}