@import '../../assets/css/common.scss';

.page {
    margin-top: 12px;
}

.title2 {
    font-weight: 700;
}

.fields {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
    max-width: 750px;
}

.buttons {
    margin-top: 20px;
}