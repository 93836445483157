@import '../../assets/css/common.scss';

.red_row_class {
    background-color: #d7baba;
}

.link {
    color: blue;
}

.green_row_class {
    background-color: #bad7bb;
}

.yellow_row_class {
    background-color: #d7d6ba;
}

.table {
    width: 100%;
    min-width: 700px;

    &_wrapper {
        overflow-x: auto;
    }

    &_scroll {
        //width: max-content;
        overflow-x: auto;
    }

    td {
        padding: 5px;

        &:nth-child(4), &:nth-child(3) {
            word-break: break-all;
        }
    }
}

.btn_create {
    display: inline-block;
    background: url(../../assets/icons/plus-in-circle-dk.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg);
    width: 12px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btns {
    display: flex;
    align-items: center;
    gap: 12px;
}