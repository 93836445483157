.static_notices_area {
    margin: 8px 0;

    .notice {
        border-radius: 8px;
        color: var(--neutral90);
        padding: 14px;

        &.warning {
            border: 1px solid var(--warning50);
            background-color: var(--warning10);
        }

        button, a {
            display: inline;
            border: none;
            padding: 0;
            margin: 0 10px;
            outline: none !important;
            color: var(--primary60);
            font-weight: 800;
            background: none;
        }
    }
}