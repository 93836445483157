.notices_list {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 99;
    width: 30%;
}

.notice {
    display: block;
    margin-top: 10px;
    position: relative;
    padding: 10px;
    border-radius: 8px;

    &.error {
        background: #fcc;
        border: 1px solid #f99;
    }

    &.info {
        background: #D9E9DF;
        border: 1px solid #1BA049;
    }

    svg {
        position: absolute;
        top: 6px;
        right: 6px;
    }
}