@import '../../assets/css/common.scss';

.tags {
    
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    width: 100%;
    &_wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    &.has_tags {
        min-height: 38px;
        margin-bottom: 20px;
    }
}

.btn {
    color: var(--primary60);
    padding: 0;
}

.input {
    width: 205px;
    input {
        height: 38px;
    }
}

.tag_adder {
    display: flex;
    gap: 5px;
}