.field_vis_editor {

    &.error {
        background: var(--red10);
    }

    .row_value {
        display: flex;
        align-items: flex-start;

        .value {
            flex-grow: 1;
        }

        .label {
            font-weight: 700;
            font-size: 15px;
            flex: 0 0 30%;
        }
    
        .display_value {
            flex-grow: 1;
            font-size: 15px;        
        }

        .btn_edit {
            cursor: pointer;
            margin-left: 10px;
        }
    }

    .row_edit {
        margin-top: 15px;
        display: none;
        align-items: center;

        &.show {
            display: flex;
        }

        .input_value {
            flex-grow: 1;
        }

        textarea {
            height: 300px;
        }

        .btn_save {
            cursor: pointer;
            margin-left: 15px;
        }
    }
}

.editor_dlg {
    width: 1200px;
    max-width: 90vw;
}

.editor_view {
    height: 800px;
    max-height: 70vh;
    overflow: auto;
}

.editor_preview {
    padding-top: 20px;
    padding-bottom: 40px;
    position: relative;

 }

.inline {
    display: inline;
}