@import '../../assets/css/common.scss';

.error {
    background: var(--red10);
}

.button {
    margin-bottom: 20px;
    gap: 10px;
    padding: 10px 20px;
}

.table {
    width: 100%;
    
    th {
        width: 200px;
    }

    &_wrapper {
        overflow-x: auto;
    }

    &_scroll {
        width: max-content;
        overflow-x: auto;
    }
}

.btn_create {
    display: inline-block;
    background: url(../../assets/icons/plus-in-circle-dk.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg);
    width: 12px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}


.dlg_add_attr {
    .fields {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}

.tab_attrs {
    position: relative;

    .btn_add_attr {
        position: absolute;
        top: 20px;
        right: 20px;
    }
}