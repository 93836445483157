.crumbs {
    height: 40px;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 16px 0;
    gap: 5px;
}
.link {
    font-size: 12px;
    text-decoration: none;
    color: #747782;
    position: relative;

    max-width: 230px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &_active {
        color: #747782;
        font-weight: 700;
    }
    &:not(.link_active):hover {
        text-decoration: underline;
    }
}

.df {
    display: flex;
    align-items: center;
    gap: 5px;
}