.input {
    width: 100%;
    border-radius: 6px;
    padding: 0px 15px;
    border: 1px solid var(--neutral40);
    transition: border .3s linear;
    font-size: 14px;
    line-height: 30px;
    &:focus {
        outline: none;
    }
    &:hover {
        border: 1px solid rgba(151, 165, 235, 0.7);
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
        &_findbtn {
            position: relative;
            .input {
                padding-right: 35px;
            }
        }
    }
}

.btn {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
        cursor: pointer;
    }
}

.filter {
    position: absolute;
    transform: translateY(50%);
}