@import '../../assets/css/common.scss';

.wf_btns {
    display: flex;
    align-items: stretch;
    
    margin-bottom: 20px;
    margin-top: 20px;

    button {
        background: none;
        color: #3B4047;
        font-size: 14px;
        padding: 0;
        text-align: left;
        border-bottom: 1px solid #E5E7EB;
        border-radius: 0;
        display: block;

        &.active,
        &:hover {
            background: none;
            font-weight: 700;
            color: var(--primary60);
        }

        &.active {
            border-bottom: 2px solid var(--primary60);
        }
    }

    .sep {
        border-bottom: 1px solid #E5E7EB;
        border-radius: 0;
        width: 20px;
    }
}

.header {
    text-align: left;
    padding: 0;
    line-height: 10px;

    &_name {
        display: inline-flex;
        align-items: center;
        gap: 5px;
        width: 100%;
    }

    &_sort {
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
    }

    &_sep {
        display: inline-flex;
        width: 4px;
        margin-left: 10px;
    }

    &.header_withsort {
        padding: 0;

        &:hover {
            cursor: pointer;
        }
    }
}

.header_name {
    min-height: 32px;
}

.table_wrap {
    position: relative;
    display: block;

    &.full_width {
        display: block;
    }

    .flex {
        display: flex;
        justify-content: flex-start;
        //max-height: 500px;
        //overflow: auto;
        //position: relative;
        //padding-right: 30px;
    }

    .btn_create {
        display: block;
        background: url(../../assets/icons/plus-in-circle-dk.svg);
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        background-size: contain;
        border: none;
        margin-top: 12px;
        margin-left: 10px;

        position: sticky;
        top: 12px;
    }
}

.table_top {
    display: flex;
    align-items: center;
    gap: 10px;
}

.table {
    position: relative;
    width: 100%;

    th {
        position: sticky;
        top: -20px;
        background: #fff;
        z-index: 9;
        
        > div {
            border-bottom: 1px solid #D5D8DE;
            padding: 0 10px;
            min-height: 33px;
        }
    }

    th:not(:first-child)::before {
        content: " ";
        border-left: 1px solid #D5D8DE;
        position: absolute;
        left: 0;
        top: 8px;
        bottom: 8px;
    }

    div,
    span {
        font-size: 14px;
    }

    &.table_search .header_name {
        border-bottom: 1px solid #D5D8DE;
    }

    thead tr.tr_subtitle {
        border-bottom: none;


        td {
            color: #5570F1;
            font-weight: 700;
            font-size: 15px;
            padding: 16px 5px;
        }
    }

    thead th {
        vertical-align: top;
        padding: 0;
    }

    tbody tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    &.table_search {
        thead tr {
            border-bottom: 2px solid var(--blue-main);
        }

        .header {
            padding-bottom: 0;
        }
    }
}

.body_cell {
    padding: 4px 8px;
    position: relative;

    .actions_popup {
        background: #F2F2F2;
        padding: 3px;
        position: absolute;
        align-items: center;
        height: 31px;
        top: 0;
        right: 0;
        display: none;
        
        box-shadow: 0px 0px 4px 0px #B0B0B0;

    }
}

.table tr:hover {
    background: #f2f2f2;
}

.table tr:hover .actions_popup {
    display: flex;
}

.button {
    padding: 9px 19px;
    gap: 10px;
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 14px;
    border: none;
}

.table_button {
    padding: 9px 19px;
    gap: 10px;
    margin-bottom: 20px;
    line-height: 23px;
    vertical-align: top;
    line-height: 23px;
    margin-left: 20px;
}

.pagination_wrapper {
    margin-top: 30px;
}

.input {
    &_global {
        margin-bottom: 20px;
        width: 322px;
    }

    &_column {
        margin: 0;
        padding: 10px;

        input {
            font-weight: 400;
            border: none;
            box-shadow: none;
            margin-left: 15px;
        }
    }
}

.input_no_hover {
    width: 100%;
    border-radius: 6px;
    padding: 12px 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    transition: border .3s linear;
    font-size: 15px;
    line-height: 18px;

    &:focus {
        outline: none;
    }

    &_wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &_findbtn {
            position: relative;

            .input {
                padding-right: 35px;
            }
        }
    }
}

.clear {
    clear: both;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit2.svg) no-repeat center center;
    width: 28px;
    height: 28px;
    border-radius: 8px;
    background-size: 20px;
    margin: 0 5px;

    &:hover {
        background-color: #E6E6E6;
    }
}

.btn_sep {
    display: inline-block;
    width: 1px;
    height: 24px;
    background: #D5D8DE;
    margin: 0 8px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg) no-repeat center center;
    width: 28px;
    height: 28px;
    border-radius: 8px;
    background-size: 20px;
    margin: 0 5px;
    
    &:hover {
        background-color: #E6E6E6;
    }
}

.btn_fav {
    display: inline-block;
    background: url(../../assets/icons/star-grey.svg) no-repeat center center;
    width: 28px;
    height: 28px;
    border-radius: 8px;
    background-size: 20px;
    margin: 0 5px;

    &.active {
        background-image: url(../../assets/icons/star-y.svg);
    }

    &:hover {
        background-color: #E6E6E6;
    }
}

.row_btn {
    display: inline-flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &:hover {
        background: #E5E7E9;
    }
}