.textarea {
    width: 100%;
    border-radius: 6px;
    padding: 8px 8px;
    border: 1px solid var(--neutral40);
    transition: border .3s linear;
    font-size: 14px;
    
    &:focus {
        outline: none;
    }
    &:hover {
        border: 1px solid rgba(151, 165, 235, 0.7);
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
        &_findbtn {
            position: relative;
            .textarea {
                padding-right: 35px;
            }
        }
    }
}