.wf_item_control {
    .draft_notice {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: #F3D9C8;
        line-height: 36px;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
    }

    .wf_notice {
        display: flex;
        align-items: center;
        background-color: var(--gray-10);
        padding: 10px 22px;
        border-radius: 4px;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
        margin-bottom: 20px;

        .msg {
            flex-grow: 1;

            svg {
                margin-right: 10px;
            }
        }

        .btn_open_item {
            margin-left: 20px;
            background: none;
            border: 1px solid #7B7E82;
            color: #7B7E82;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            line-height: 36px;
            padding: 0 25px;
        }

        .btn_hide {
            margin-left: 33px;
            cursor: pointer;
        }
    }

    .btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > * {
            margin-left: 8px;
            height: 30px;
            white-space: nowrap;
        }
    }
}