.autocomplete_wrapper {
    .async_select {
        border: 1px solid var(--neutral40);
        border-radius: 6px;
        min-width: 200px;

        > div {
            border: none;
            border-radius: 6px;
        }

        input {
            opacity: 1 !important;
        }
    }
}