.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 28px 0px 24px;
    height: 52px;
    border-bottom: 2px solid #E3E5EA;
    width: 100%;
    position: relative;
}
.actions {
    display: flex;
    align-items: center;
    gap: 18px;

    button:not(.btn_icon) {
        height: 38px;
        
    }
}

.subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--gray-70);
}

.search {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    margin-left: -566px;

    @media (max-width: 1570px) {
        margin-left: 0;
        left: 231px;
    }
}

.sitetitle {
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 24.5px;
    color: #23262D;
}

.logo_link {
    display: flex;
    align-items: center;
    gap: 10px;
}

button.btn_icon {
    padding: 0;
}

.dd_mode {
    .toggle, .toggle:active, .toggle:focus-visible {
        background: none;
        border: none;
        color: var(--neutral90);
        font-weight: 800;

        &::after {
            background: url(../../assets/icons/dd-down.svg) no-repeat center center;
        }

        &:global(.show):after { 
            background: url(../../assets/icons/dd-up.svg) no-repeat center center !important;
        }
    }
}

button.btn_chat {
    width: 20px;
    height: 20px;
    background: url(../../assets/icons/btn-chat.svg) no-repeat center center;
    background-size: contain;
}