.modal-content {
    border: none;
    border-radius: 8px;
    box-shadow: 0px 6.4px 28px 0px #0000001F;
    box-shadow: 0px 1.2px 18px 0px #00000014;

}

.modal-header {
    border-bottom: none;
    align-items: flex-start;
}

.modal-header .btn-close {
    margin: 0;
}

.modal-title {
    flex-grow: 1;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    color: var(--neutral90);
}

.modal-footer {
    border-top: none;
    justify-content: flex-end;
}

.modal-footer .btn {
    font-size: 14px;
    border: 2px solid #E8762D;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 4px;
}

.modal-footer .btn.btn-primary {
    color: #fff;
    background: #E8762D;
}

.modal-footer .btn.btn-secondary {
    background: #fff;
    color: #E8762D;
}

.tooltip {
    background: #fff;
    color: #000;
    font-size: 12px;
    opacity: 1;
    padding: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

@media (min-width: 1001px) {
    .tooltip.mob-only {
        display: none !important;
    }
}

body > div:not(#root) {
    display: none;
}