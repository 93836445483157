@import '../../assets/css/common.scss';

.page .title {
    margin-bottom: 30px;
}

.table {
    width: 100%;
    min-width: 700px;
    margin-bottom: 70px;
    th {
        width: 300px;
    }
    &_wrapper {
        overflow-x: auto;
    }
    &_scroll {
        width: max-content;
        overflow-x: auto;
    }
}

.btn_create {
    display: inline-block;
    background: url(../../assets/icons/plus-in-circle-dk.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg);
    width: 12px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.buttons {
    display: flex;
    gap: 5px;
    margin-top: 20px;

    .btn {
        padding: 8px 11px;
    }

    .spacer {
        flex-grow: 1;
    }

    .btn_check {
        font-size: 15px;
        font-weight: 700;
        color: #8598F4;

        &:before {
            display: inline-block;
            content: ' ';
            background: url(../../assets/icons/btn_check.svg) no-repeat center center;
            width: 14px;
            height: 16px;
            background-size: contain;
            fill: #8598F4;
            margin-right: 10px;
        }
    }
}

.add_task_dlg {
    max-width: 750px;

    .buttons {
        display: flex;
        gap: 5px;
        justify-content: flex-end;
        margin-top: 35px;
    }
}

.create_task_dlg {
    max-width: 750px;
    margin-bottom: 70px;

    .buttons {
        display: flex;
        gap: 5px;
        justify-content: flex-end;
        margin-top: 35px;
    }
}

.fields {
    width: 100%;

    th {
        font-weight: 400;
        font-size: 15px;
        text-transform: uppercase;
        width: 290px;
    }
    th, td {
        padding: 5px;
    }
}

.separator {
    border-top: 1px solid #d9d9d988;
    margin-top: 20px;
    padding-top: 20px;
}

.params {
    width: 100%;

    th {
        width: 290px;
    }
    th, td {
        padding: 5px;
    }
}

.show_add_task {
    .add_task_dlg {
        display: block;
    }
}

.show_create_task {
    .create_task_dlg {
        display: block;
    }
}

.tasks_wrapper {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    > span {
        display: block;
    }
}

.task_params {

    width: 100%;

    th {
        width: 290px;
    }

    th, td {
        padding-bottom: 15px;
    }
}

.run_result {
    text-align: center;

    .loader {
        margin: 20px;
    }

    textarea {
        min-height: 150px;
        margin-top: 10px;
    }
}

.btn_run {
    display: inline-block;
}

.dlg_create {
    .fields {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.btn_add_schedule {
    min-width: 0;
    width: 130px;
    text-align: left;
    padding: 0;
    justify-content: flex-start;
}

.schedules {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .schedule_item {
        padding-bottom: 8px;
        border-bottom: 1px solid var(--neutral50);
        table {
            width: 100%;

            th, td {
                width: 50%;
            }
        }
    }

    th, td {
        padding: 4px 0;
        font-weight: 400;
    }
}

.btns {
    display: flex;
    justify-content: flex-end;
}

.pill.status_ {
    display: none;
}

.pill.status_failed, .pill.status_ошибка {
    background-color: var(--red10);
}

.pill.status_finished, .pill.status_выполнена {
    background-color: var(--primary10);
}