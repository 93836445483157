.params {
    width: 100%;
    
    th {
        width: 290px;
        font-weight: 400;
    }
    th, td {
        padding: 5px;
    }
}

.fields, .params {
    input[type="text"], input[type="number"], input[type="date"], input[type="password"] {
        border: 1px solid #B2B2B2;
        box-shadow: none;
    }
}

.label {
    color: #717681;
    margin-bottom: 4px;
}