@import '../../assets/css/common.scss';

.table {
    width: 100%;
    min-width: 700px;
    margin-bottom: 70px;
    th {
        width: 200px;
    }
    &_wrapper {
        overflow-x: auto;
    }
    &_scroll {
        width: max-content;
        overflow-x: auto;
    }
}

.btn_create {
    display: inline-block;
    background: url(../../assets/icons/plus-in-circle-dk.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg);
    width: 12px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.custom_attributes_data {
    margin-top: 50px;
    max-width: 750px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -15px;

    > div {
        flex: 0 0 33%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.lbl_tasks {
    font-weight: 700;
    font-size: 15px;
    margin-top: 30px;
}

.tab_white {
    display: flex;
    flex-direction: column;
    gap: 12px;
}