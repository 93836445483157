@import '../../assets/css/common.scss';

.page {
    &.queryPage {
        display: none;
        align-items: flex-start;
        flex-grow: 1;
        max-width: 1600px;

        &.loaded {
            display: flex;
        }

        .mainContent {
            flex-grow: 1;
            min-width: 300px;
        }

        .rightBar {
            flex: 0 0 253px;
            padding: 0;
            margin-left: 30px;
        }
    }
}
.error {
    color: red;
}

.input {
    width: 322px;
    margin-bottom: 20px;
}

.button {
    margin-bottom: 20px;
    gap: 10px;
    padding: 10px 20px;
}

.table {
    width: 100%;
    min-width: 700px;
    margin-bottom: 70px;

    th {
        width: 200px;
    }

    &_wrapper {
        overflow-x: auto;
    }

    &_scroll {
        width: max-content;
        overflow-x: auto;
    }
}

.btn_create {
    display: inline-block;
    background: url(../../assets/icons/plus-in-circle-dk.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg);
    width: 12px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.general_data {
    max-width: 750px;
    border: 1px solid #d9d9d9;
    padding: 0 8px;

    .data_row {
        padding: 16px 8px;

        &:not(:last-child) {
            border-bottom: 1px solid #d9d9d988;
        }
    }
}

.custom_attributes_data {
    margin-top: 50px;
    max-width: 750px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -15px;

    >div {
        flex: 0 0 33%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.lbl_tasks {
    font-weight: 700;
    font-size: 15px;
    margin-top: 30px;
}
