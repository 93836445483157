



.versions {
    &_title {
        margin-left: 3px;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 13px;
        &_index {
            margin-left: 4px;
            span {
                font-weight: 700;
            }
        }
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
        max-height: 560px;
        padding-left: 5px;
        padding-bottom: 12px;
        overflow-y: auto;
        gap: 12px;
        &::-webkit-scrollbar {
            width: 13px;
        }
        &::-webkit-scrollbar-track {
            background: #E8E8E8;
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--gray-70);
            border-radius: 20px;
            border: 4px solid #E8E8E8;
        }
    }
}

.versions_dd {
    button, button:active, button:focus-visible, button:hover {
        background: #fff !important;
        border-radius: 8px;
        line-height: 24px;
        border: none;
        color: var(--neutral90) !important;
        font-size: 12px;
        padding: 0 8px;
    }

    .version_name {
        font-size: 14px;
    }

    .version_date, .version_responsible {
        font-size: 12px;
        color: var(--neutral50);
    }

    .menu {
        padding: 4px;
        border: none;
        border-radius: 8px;
        box-shadow: 0px 3.2px 9px 0px #00000029;
        box-shadow: 0px 0.6px 1.8px 0px #0000001A;
        box-shadow: 0px -1.5px 6px 0px #0000000F;
        max-height: 400px;
        overflow: auto;
    }

    .item {
        background: #fff;
        border-radius: 8px;

        &.current {
            background: #E8E8E8;
        }
        
        &:hover {
            background: #F2F2F2;
        }
    }

    .toggle:after {
        vertical-align: 2px;
    }
}

.version {
    display: block;
    text-decoration: none;
    width: 234px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px 12px 15px;
    
    &_title {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 3px;
    }
    &_create {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 10px;
    }
    &_description {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        line-height: 15px;
        &_text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 5px;
        }
    }

    &.active {
        background-color: var(--orange-primary);

        .version_title, .version_create, .version_description span {
            color: #fff;
        }
    }
}

.version_user_link {
    cursor: pointer;
    color: var(--bs-link-color);
}