.wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    button {
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
        border-radius: 8px;
        border: 1px solid transparent;

        &:hover {
            border-color: var(--primary60);
            color: var(--primary60);
        }

        &.btn_active {
            border-color: var(--primary60);
            color: var(--primary60);
        }
    }

    select {
        border-radius: 6px;
        padding: 8px 12px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        transition: border 0.3s linear;
        font-size: 14px;
        line-height: 18px;
        margin-left: 8px;

        &:hover {
            border: 1px solid var(--primary60);
        }
    }
}

.label {
    margin-right: 16px;
}

.sep {
    flex-grow: 1;
}

