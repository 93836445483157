@import '../../assets/css/common.scss';

.value {
    display: flex;
    align-items: center;
    gap: 10px;

    .autocomplete_comp {
        flex-grow: 1;
    }
}

.btn_clear {
    cursor: pointer;
}