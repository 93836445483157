@import '../../assets/css/common.scss';

.error {
    color: red;
}

.general_data_create {
    max-width: 750px;
    border: 1px solid #d9d9d9;
    padding: 0 8px;

    .data_row {
        padding: 16px 8px;
        padding-left: 0;
        display: flex;
        align-items: flex-start;

        &:not(:last-child) {
            border-bottom: 1px solid #d9d9d988;
        }

        &,
        &_desc {
            .label {
                font-weight: bold;
                font-size: 15px;
                flex: 0 0 30%;
                margin-top: 12px;
            }

            .value {
                flex-grow: 1;
                flex: 0 0 30%;

                &_dq {
                    flex-grow: 1;
                    flex: 0 0 30%;
                    display: flex;
                }
            }

            .desc {
                flex-grow: 1;
                font-size: 12px;
                margin-left: 10px;
                min-height: 44px;
            }

            .editor {
                flex-grow: 1;
                padding: 0;

                * {
                    font-weight: 400 !important;
                }
            }

            input,
            textarea {
                border-color: #b2b2b2;
            }

            .inputs {
                input {
                    margin-bottom: 10px;
                }
            }

            .actions {
                .btn_add {
                    cursor: pointer;
                    margin-left: 15px;
                    border: 1px solid #C85003;
                    border-radius: 4px;
                    width: 44px;
                    height: 44px;
                    flex: 0 0 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        fill: #C85003;
                    }
                }
            }
        }

        &_desc {
            display: block;
            padding: 16px 8px;

            .label {
                margin-bottom: 10px;
            }

            textarea {
                min-height: 300px;
                width: 60%;
            }
        }
    }


}

.btns {
    display: flex;
    align-items: center;
    gap: 12px;
}

.button {
    margin-bottom: 20px;
    gap: 10px;
    padding: 10px 20px;
    margin-right: 5px;
}

.table {
    width: 100%;
    min-width: 700px;
    margin-bottom: 70px;

    th {
        width: 200px;
    }

    &_wrapper {
        overflow-x: auto;
    }

    &_scroll {
        width: max-content;
        overflow-x: auto;
    }
}

.btn_create {
    display: inline-block;
    background: url(../../assets/icons/plus-in-circle-dk.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg);
    width: 12px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.dlg_create {
    .fields {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}