@import '../../assets/css/common.scss';

.value {
    display: flex;
    align-items: center;
}

.field_editor {
    .label {
        margin-bottom: 0;
    }
}