@import '../../assets/css/common.scss';

.diagram_div {
    width: 100%;
    height: 100%;
    flex: 1 1;
    background: whitesmoke;
}

.artifact_model {
  width: 100%;
  height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.topbar {
  @include white-block;
  padding: 5px;
  margin-top: 12px;
}

.dg_wrap {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1 1;

}

.dg_filter, .lnk_tags_filter {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  flex-wrap: wrap;

  .btn_filter {
    background: #fff;
    border: 1px solid var(--blue-70);
    color: var(--blue-70);
    display: none;
    font-weight: 400;
    white-space: nowrap;

    &.shown {
      display: block;
    }

    &.active {
        background: var(--blue-70);
        color: #fff;
    }
  }

  .btn_filter_dir {
    background: #fff;
    border: 1px solid var(--primary60);
    color: var(--primary60);
    font-weight: 400;
    white-space: nowrap;

    &.active {
        background: var(--primary60);
        color: #fff;
    }
  }
}

.tags_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.filter_tags {

  flex: 0 0 40px;
  width: 100%;
  min-height: 40px;
  background: #fff;
  display: flex;
  align-items: center;
  

  label {
    padding-left: 15px;
    font-weight: bold;
    flex: 0 0 60px;
  }

  .btn_filter_tag {
    padding: 0 10px;
    background: #fff;
    border: 1px solid var(--blue-70);
    color: var(--blue-70);
    font-weight: 400;
    white-space: nowrap;

    &.active {
      background: var(--blue-70);
      color: #fff;
    }
  }
}

.rightToolBar {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 40px;
    background: #fff;
    padding: 8px;
    z-index: 19;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    -moz-box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);
    -webkit-box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);
    box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);
  
    a {
      display: block;
      color: #777;
      margin-bottom: 5px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      text-align: center;
  
      &:hover {
        color: #000;
      }
    }   
}

.leftToolBar {
  position: absolute;
  top: 10px;
  left: 0;
  width: 140px;
  background: #fff;
  padding: 8px;
  z-index: 19;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;

  -moz-box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);
  -webkit-box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);
  box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);

  a {
    display: block;
    color: #000;
    cursor: pointer;
    width: 100%;
    line-height: 30px;
    text-align: center;
    border-radius: 8px;

    &.active, &:hover {
      background-color: #ccc;
    }


  }   
}