@import '../../assets/css/common.scss';

main {
  flex-direction: column;
  flex: 1 1;
}

.diagram_div {
    width: 100%;
    height: 100%;
    flex: 1 1;
    background: whitesmoke;
}

.filter_domains {
  flex: 0 0 40px;
  width: 100%;
  min-height: 40px;
  background: #fff;
  display: flex;
  align-items: center;

  label {
    padding-left: 15px;
    font-weight: bold;
    flex: 0 0 100px;
  }

  .btn_filter_domain {
    padding: 5px 10px;
    margin-left: 5px;
    background: #fff;
    border: 1px solid var(--blue-70);
    color: var(--blue-70);

    &.active {
      background: var(--blue-70);
      color: #fff;
  }
  }
}

.filter_tags, .lnk_tags_filter, .nodes_search {
  flex: 0 0 40px;
  width: 100%;
  min-height: 40px;
  background: #fff;
  display: flex;
  align-items: center;

  label {
    padding-left: 15px;
    font-weight: bold;
    flex: 0 0 100px;
  }
}

.nodes_search {
  input {
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 5px;
  }
}

.filter_tags {

  .btn_filter_tag {
    padding: 0 10px;
    margin-left: 5px;
    background: #fff;
    border: 1px solid var(--primary60);
    color: var(--primary60);

    &.active {
      background: var(--primary60);
      color: #fff;
  }
  }
}

.lnk_tags_filter {

  .btn_filter {
    background: #fff;
    border: 1px solid var(--primary60);
    color: var(--primary60);
    display: none;
    padding: 5px 10px;
    margin-left: 5px;

    &.shown {
      display: inline-flex;
    }

    &.active {
        background: var(--primary60);
        color: #fff;
    }
  }

}

.domains_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dg_outer_wrap {
  display: flex;
  align-items: stretch;
  height: 100%;
  gap: 8px;
  margin-top: 12px;
}

.topbar {
  @include white-block;
  padding: 6px;
}

.right_sidebar {
  @include white-block;
  padding: 10px;
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;

  .search {
    padding: 5px;
  }

  .entities_list {
    width: 100%;
    flex-grow: 1;
    overflow: auto;

    .entity_item {
      display: block;
      padding: 3px 6px;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: all-scroll;
      margin: 5px;
      color: var(--primary60);
    }
  }
}

.dg_wrap {
    width: 100%;
    height: 100%;
    flex: 1 1;
    position: relative;
}

.comments_panel {
  @include white-block;
  padding: 10px;
  flex: 0 0 300px;

  label {
    display: block;
    text-align: center;
  }

  .comment_add {
    text-align: right;
    padding: 3px;

    button {
      margin-top: 3px;
    }
  }

  .comment {
    margin: 5px;
    border: 1px solid var(--blue-70);
    border-radius: 4px;
    padding: 4px;

    .title {
      font-weight: bold;
    }
  }
}

.leftToolBar {
  position: absolute;
  top: 110px;
  left: 0;
  width: 40px;
  background: #fff;
  padding: 8px;
  z-index: 19;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;

  -moz-box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);
  -webkit-box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);
  box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);

  a.btn {
    display: block;
    width: 30px;
    text-align: center;
    padding: 7px 0;
    cursor: pointer;
    border-radius: 4px;

    &.active {
      background-color: var(--primary10);
      //img { filter: invert(100%); }
    }

    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}

.rightToolBar {
    position: absolute;
    top: 110px;
    right: 40px;
    width: 40px;
    background: #fff;
    padding: 8px;
    z-index: 19;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    -moz-box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);
    -webkit-box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);
    box-shadow: 4px 2px 5px 0px rgba(34, 60, 80, 0.4);
  
    a {
      display: block;
      color: #777;
      margin-bottom: 5px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      text-align: center;
  
      &:hover {
        color: #000;
      }
    }   
}