@import '../../assets/css/common.scss';

.page .title {
    margin-bottom: 30px;
}

.table {
    width: 100%;
    margin-bottom: 70px;

    th {
        width: 200px;
    }

    &_wrapper {
        overflow-x: auto;
    }

    &_scroll {
        width: max-content;
        overflow-x: auto;
    }
}

.btn_create {
    display: inline-block;
    background: url(../../assets/icons/plus-in-circle-dk.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}



.btn_upload {
    background: url(../../assets/icons/upload.svg) no-repeat center center;
    background-size: 20px 20px;
    width: 44px;
    height: 44px;
    background-color: var(--blue-main);
    border: none;
    border-radius: 4px;
}

.btn_download {
    background: url(../../assets/icons/download.svg) no-repeat center center;
    background-size: 20px 20px;
    width: 44px;
    height: 44px;
    background-color: var(--blue-main);
    border: none;
    border-radius: 4px;
    margin-left: 10px;
}

.tab_data {
    textarea {
        width: 100%;
        height: 600px;
        margin-top: 20px;
        padding: 10px;
        background-color: var(--gray-25);
    }
}

.tbl_mappings {
    margin-bottom: 300px;

    th,
    td {
        padding: 10px;
    }
}

.table_data {

    th,
    td {
        padding: 18px 10px;
        font-size: 15px;
    }

    th {
        border-bottom: 1px solid #d9d9d988;
    }
}

.table_wrap {
    width: 100%;
    overflow: auto;
    
}