@import '../../assets/css/common.scss';

.fav {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.fav h2 {
    margin-bottom: 12px;
}

.fav .filter {
    display: flex;
    margin-bottom: 12px;
}

.fav .list {
    
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: -12px;
    margin-right: -12px;
    overflow: auto;
    flex: 1;
    min-height: 0;
}

.fav .item {
    
    border-radius: 16px;
    padding: 12px;

    &:hover {
        background: var(--neutral05);
    }

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            width: 20px;
            height: 20px;
            fill: #FFC400;
            flex: 0 0 20px;

            path {
                fill: #FFC400;
            }
        }
    }

    h3 {
        padding: 0;
        margin: 0;
        color: var(--neutral90);
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .star {
        cursor: pointer;
    }

    a {
        overflow: hidden;
    }
}