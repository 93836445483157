@import '../../assets/css/common.scss';


.table {
    width: 100%;
    min-width: 700px;
    margin-bottom: 70px;
    th {
        width: 300px;
    }
    &_wrapper {
        overflow-x: auto;
    }
    &_scroll {
        width: max-content;
        overflow-x: auto;
    }
}

.btn_create {
    display: inline-block;
    background: url(../../assets/icons/plus-in-circle-dk.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg);
    width: 12px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}
