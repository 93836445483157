@import '../../assets/css/common.scss';

.table {
    width: 100%;
    min-width: 700px;
    margin-bottom: 70px;
    th {
        width: 200px;
    }
    &_wrapper {
        overflow-x: auto;
    }
    &_scroll {
        width: max-content;
        overflow-x: auto;
    }
}

.btn_create {
    display: inline-block;
    background: url(../../assets/icons/plus-in-circle-dk.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg);
    width: 12px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.add_systems_list {
    max-height: 300px;
    overflow: auto;
    padding: 0 10px;

    .new_system_item {
        margin: 5px 0;
    }
}

.dlg_create {
    .fields {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}

.tab_white {
    display: flex;
    flex-direction: column;
    gap: 12px;
}