@import '../../assets/css/common.scss';

.tag {
    @include pill-grey-big;
    cursor: pointer;
    
    &:hover {
        border-color: rgba(151, 165, 235, 0.7);
    }
    &:active {
        color: var(--white);
        background-color: #B2B2B2;
        border-color: #B2B2B2;
    }
    &_more {
        background-color: rgba(178, 178, 178, 0.8);
        border-color: rgba(178, 178, 178, 0.8);
    }

    a {
        text-decoration: none;
        color: var(--gray-100);
    }

    svg {
        margin-left: 10px;
        padding: 3px;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        border-radius: 3px;

        &:hover {
            background: #888;
        }
    }
}