.aside {
    padding-top: 20px;
    padding-right: 0;
    height: 100%;
    min-height: calc(100vh - 120px);
    width: 200px;

    &.minimized {
        width: 32px;
    }
    
    ul {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-left: 0;
        margin-bottom: 50px;
    }

    .settings_link {
        margin-top: 10px;
    }

    .link {
        color: var(--gray-100);
        display: flex;
        align-items: center;
        height: 32px;
        padding: 0 5px;
        gap: 10px;
        text-decoration: none;
        border-radius: 8px;
        
        .icon {
            width: 20px;
            text-align: center;
        }
        .count {
            margin: 0;
            min-width: 20px;
            text-align: center;
            padding: 2px;
            border-radius: 8px;
            
        }
        .title {
            font-size: 14px;
            font-weight: 700;
            
            
            
        }
        &_active {
            
            background-color: var(--blue-main);
            
            .count {
                background-color: #F1F3F6;
            }
        }

        
    }

    &.minimized .link {
        justify-content: center;
    }

    a.link:hover {
        background-color: var(--blue-main);
    }

    .recent_header {
        margin-top: 16px;

        @media (max-width: 1000px) {
            display: none;
        }
    }

    .recent {
        
        a {
            //padding-left: 35px;

            span.title {
                font-size: 14px;
                font-weight: 400;
                color: var(--primary60);
            }
        }

        @media (max-width: 1000px) {
            display: none;
        }
    }
}